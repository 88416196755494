import * as React from 'react';
import { Box, Text, useThemeUI } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type Props = {
  videoUrl: string;
};

const Video: React.FC<Props> = ({ videoUrl }) => {
  const { theme } = useThemeUI();
  const { t } = useTranslation();
  const loadingRef = React.useRef<HTMLDivElement>(null);

  const onProgress = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    e.target.style.opacity = 1;
    if (loadingRef.current) {
      loadingRef.current.style.display = 'none';
    }
  };

  return (
    <Box
      sx={{
        mb: 5,
        position: 'relative',
      }}
    >
      <Box
        ref={loadingRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'lunar',
          bg: 'silver',
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <rect x="20.833" y="45.833" width="41.667" height="25" rx="6" fill="#90ACC9" />
          <path
            d="M78.167 48.072v20.523c0 1.696-1.978 2.622-3.28 1.536l-6.5-5.416a2 2 0 0 1-.72-1.537v-9.69a2 2 0 0 1 .72-1.536l-.64-.768.64.768 6.5-5.417c1.302-1.085 3.28-.159 3.28 1.537z"
            fill="#90ACC9"
            stroke="#90ACC9"
            strokeWidth="2"
          />
          <circle cx="29.166" cy="33.333" fill="#90ACC9" r="8.333" />
          <circle cx="54.166" cy="33.333" r="8.333" fill="#90ACC9" />
        </svg>
        <Text>Media loading...</Text>
      </Box>
      <video
        controls
        controlsList="nodownload"
        src={t(videoUrl)}
        width="100%"
        onProgress={onProgress}
        style={{ opacity: 0, cursor: 'pointer', backgroundColor: theme.colors?.silver as string }}
      >
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default Video;
