import * as React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { useToggle } from '../hooks/useToggle';

type Props = {
  title: string;
};

const ExpandableText: React.FC<Props> = ({ title, children }) => {
  const transcriptRef = React.useRef<HTMLDivElement>(null);
  const [collapse, onCollapse] = useToggle(true);

  return (
    <Box
      sx={{
        borderRadius: '16px',
        width: '100%',
        bg: 'clouds',
        py: 3,
        px: 5,
        mt: 5,
      }}
    >
      <Flex
        sx={{
          borderRadius: '16px',
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onCollapse}
      >
        <Text
          sx={{
            flex: 1,
            lineHeight: '24px',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Text>
        {collapse ? (
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m9 13.5 9 9 9-9" stroke="#1F7BFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        ) : (
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m27 22.5-9-9-9 9"
              stroke="#1F7BFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </Flex>

      <Text
        ref={transcriptRef}
        sx={{
          lineHeight: '24px',
          whiteSpace: 'pre-line',
          pt: 4,
          display: collapse ? 'none' : 'block',
        }}
      >
        {children}
      </Text>
    </Box>
  );
};

export default ExpandableText;
