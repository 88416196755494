import * as React from 'react';
import { graphql } from 'gatsby';
import { Flex, Heading, Text, useThemeUI } from 'theme-ui';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { TopicData } from '../components/types';
import Video from '../components/Video';
import ExpandableText from '../components/ExpandableText';

type Props = {
  data: {
    topic: TopicData;
  };
};

const Topic: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { topic } = data;
  const { theme } = useThemeUI();
  return (
    <>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        <Flex
          sx={{
            marginBottom: '30px',
            color: 'science',
            marginLeft: '-8px',
            cursor: 'pointer',
          }}
        >
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 14 9 24l10 10M9 24h30"
              stroke={theme.colors?.science as string}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Text
            sx={{
              fontSize: 3,
              lineHeight: '28px',
              fontWeight: 'bold',
              my: 3,
              ml: 1,
            }}
          >
            <Trans>back</Trans>
          </Text>
        </Flex>
      </Link>
      <Heading as="h1" variant="styles.h1">
        <Trans>{topic.title}</Trans>
      </Heading>
      {topic.videoUrl && <Video videoUrl={topic.videoUrl} />}
      <Text
        sx={{
          lineHeight: '24px',
        }}
      >
        {t(topic.description)}
      </Text>
      <ExpandableText title={t('transcript')}>
        <Trans>{topic.transcript}</Trans>
      </ExpandableText>
    </>
  );
};

export default Topic;

export const query = graphql`
  query ($id: String!, $language: String!) {
    topic(id: { eq: $id }) {
      id
      keywords
      maintopic
      videoUrl
      order
      title
      description
      transcript
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
